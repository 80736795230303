<template>
  <b-card
    no-body
    :class="{ open: visible }"
    :style="resolveBorderColorByAirline(item.airline)"
    @mouseenter="collapseOpen"
    @mouseleave="collapseClose"
  >
    <b-card-header
      :class="{ collapsed: !visible , 'bg-light-warning' : selectedTrip && selectedTrip.id === item.id || visible}"
      :aria-expanded="visible ? 'true' : 'false'"
      :aria-controls="collapseItemID"
      role="tab"
      class="p-0"
      data-toggle="collapseflight"
      @click="updateVisible(!visible)"
    >
      <slot name="header">
        <div class="w-100">
          <b-row
            :class="`d-flex-between flex-md-nowrap ${(appBreakPoint !== 'xl') ? 'font-small-3': ''}`"
            style="max-height: 55px;"
            no-gutters
          >
            <!-- ANCHOR - 1 Logo -->
            <b-col
              class="d-flex-center"
              cols="2"
              md="1"
            >
              <div :style="`${['1G', 'F1', 'AK', 'TH', '1B'].includes(item.source) ? `position: relative; box-sizing: border-box; overflow: hidden; width: 100%;` : ''} `">
                <div :class="`d-flex-center ml-1 ${resolveBadgeSource(item.source, item?.contentSource)}`">
                  <IAmLogoAirline
                    :airline="item.segments[0].operating === 'BL' ? item.segments[0].operating : (item.airline.split(' | ')[0] || item.operator)"
                    :height="40"
                  />
                </div>
              </div>
            </b-col>

            <!-- ANCHOR - 2 Số hiệu -->
            <b-col
              class="d-flex flex-column justify-content-center align-items-center"
              cols="1"
              xl="1"
            >
              <div
                v-b-tooltip.hover.html.v-primary
                class="font-weight-bolder align-center"
                :title="item.segments.map(item => resolveAirlineFlightNumber(item.airline, item.flightNumber)).join(' | ')"
              >
                <span
                  class="text-airline text-nowrap fw-700"
                  :class="isMobileView ? '' : 'font-medium-2'"
                >
                  {{ resolveAirlineFlightNumber(item.segments[0].airline, item.segments[0].flightNumber) }}
                </span>
              </div>

              <!-- ANCHOR - Tên máy bay - Mobile -->
              <div
                v-if="isMobileView && getShowAircraft"
                class="text-center"
              >
                <span
                  v-b-tooltip.hover.html.v-primary
                  class="text-nowrap text-body fw-700"
                  :title="getAirCraftByIata(item.segments[0].airCraft) || item.segments[0].airCraft"
                >
                  {{ convertShortenAircraft(item.segments[0].airCraft) }}
                </span>
              </div>
            </b-col>

            <!-- ANCHOR - 3 Thời gian bắt đầu - kết thúc -->
            <b-col
              md="2"
              class="d-none d-md-block text-black text-center"
            >
              <span
                v-b-tooltip.hover.html.v-primary.top.window
                :class="['xs'].includes(appBreakPoint) ? 'font-small-1' : 'font-medium-1'"
                :title="`${$t('flight.totalDuration')}: ${toHoursAndMinutes(item.duration)}`"
              >
                {{ `${convertISODateTime(item.departure.at, item.departure.timezone).time} - ${convertISODateTime(item.arrival.at, item.arrival.timezone).time}` }}
              </span>
            </b-col>

            <!-- ANCHOR - 4 Điểm bắt đầu - điểm dừng - điểm kết thúc -->
            <b-col
              cols="5"
              md="3"
              class="text-center"
              :class="isMobileView ? '' : 'font-medium-2'"
            >
              <div>
                <span
                  v-for="(point, pointIndex) in getTripNameBySegments(item)"
                  :key="pointIndex"
                >
                  <span
                    v-b-tooltip.hover.html.v-primary.top.window
                    :class="pointIndex !== 0 && pointIndex !== getTripNameBySegments(item).length - 1 ? `font-weight-light ${isMobileView ? 'font-small-2' : 'font-medium-1'}` : ''"
                    :title="isMobileView ? '' : `<div>${pointIndex !== 0 && pointIndex !== getTripNameBySegments(item).length - 1 ? 'Điểm dừng:' : ''} ${point.name}</div>
                    ${point.transitTime ? `<div>${$t('ticket.flightsInfo.transitTime')}: ${point.transitTime}</div>` : ''}`"
                  >
                    {{ point.iataCode }}
                  </span>
                  <span v-if="pointIndex !== getTripNameBySegments(item).length - 1"> - </span>
                </span>
              </div>
              <div
                v-if="isMobileView"
                :class="isMobileView ? 'font-weight-bold': ''"
              >
                {{ `${convertISODateTime(item.departure.at, item.departure.timezone).time} - ${convertISODateTime(item.arrival.at, item.arrival.timezone).time}` }}
              </div>
            </b-col>

            <!-- ANCHOR - 5 Tên máy bay -->
            <b-col
              v-if="getShowAircraft"
              class="d-none d-md-inline"
              cols="1"
            >
              <span
                v-b-tooltip.hover.html.v-primary
                class="text-nowrap font-weight-bold"
                :class="isMobileView ? '' : 'font-medium-2'"
                :title="getAirCraftByIata(item.segments[0].airCraft) || item.segments[0].airCraft"
              >
                {{ convertShortenAircraft(item.segments[0].airCraft) }}
              </span>
            </b-col>

            <!-- ANCHOR - 6 Hạng vé -->
            <b-col
              v-if="getShowBookingClass"
              cols="1"
              class="text-warning font-weight-bold text-left d-none d-md-block text-truncate"
              :class="isMobileView ? '' : 'font-medium-2'"
            >
              <div class="d-flex-center">
                <template v-if="['TAP'].includes(item.clientId) && ['VN1A'].includes(item.source) && !item.domestic">
                  <b-img
                    style="height: 24px;"
                    :src="require(`@icons/luggage.svg`)"
                  />
                </template>
                <template v-else>
                  <div v-if="getMinFareOptions(item.fareOptions).luggage !== null">
                    <b-img
                      v-if="getMinFareOptions(item.fareOptions).luggage"
                      style="height: 24px;"
                      :src="require(`@icons/luggage.svg`)"
                    />
                    <b-img
                      v-else
                      style="height: 24px;"
                      :src="require(`@icons/no-luggage.svg`)"
                    />
                  </div>
                </template>
                <span
                  v-b-tooltip.hover.html.v-primary.top.window
                  :title="getMinFareOptions(item.fareOptions).groupClassName"
                >
                  {{ getMinFareOptions(item.fareOptions).bookingClassAndSeatAvail }}
                </span>
              </div>
            </b-col>

            <!-- ANCHOR - 7 Giá vé -->
            <b-col
              class="d-flex flex-column align-items-start justify-content-center"
              cols="2"
            >
              <div v-if="!getMinFareOptions(item.fareOptions).price">
                <!-- Hết chỗ -->
                <span
                  id="flight.full"
                  class="text-danger"
                >{{ $t('flight.full') }}</span>
              </div>
              <div v-else>
                <div
                  v-if="getShowPrice !== 'HIDE_FARE'"
                  :class="selectedTrip && selectedTrip.fareOptions && selectedTrip.fareOptions.id === getMinFareOptions(item.fareOptions).id ? 'text-success' : 'text-warning'"
                >
                  <div
                    :class="`text-danger fw-600 d-flex-center
                    ${isMobileView ? ' font-small-4' : 'font-medium-2'}`"
                  >
                    {{ formatCurrency(getMinFareOptions(item.fareOptions).price) }}
                    <!-- <span
                      v-if="getMinFareOptions(item.fareOptions).discount > 0"
                      class="badge discount-badge badge-pill bg-danger badge-glow text-nowrap"
                    >
                      %
                    </span> -->
                  </div>
                </div>

                <div class="d-flex-center">
                  <del
                    v-if="getMinFareOptions(item.fareOptions).discount && getShowPrice !== 'HIDE_FARE'"
                    class="text-secondary font-weight-bolder position-relative"
                    :class="isMobileView ? 'font-small-2' : 'font-small-4'"
                  >
                    <span>{{ formatCurrency(getMinFareOptions(item.fareOptions).price + getMinFareOptions(item.fareOptions).discount) }}</span>
                    <!-- <span
                      v-if="getMinFareOptions(item.fareOptions).discount > 0"
                      class="badge discount-badge-del-price badge-pill bg-danger badge-glow text-nowrap"
                    >
                      %
                    </span> -->
                  </del>
                </div>

                <div
                  v-if="isMobileView"
                  class="d-flex-center"
                >
                  <div
                    v-if="getShowBookingClass"
                    cols="1"
                    class="d-flex-center justify-content-end text-warning font-weight-bold"
                  >
                    <template v-if="['TAP'].includes(item.clientId) && ['VN1A'].includes(item.source) && !item.domestic">
                      <b-img
                        style="height: 16px;"
                        :src="require(`@icons/luggage.svg`)"
                      />
                    </template>
                    <template v-else>
                      <div v-if="getMinFareOptions(item.fareOptions).luggage !== null">
                        <b-img
                          v-if="getMinFareOptions(item.fareOptions).luggage"
                          style="height: 16px;"
                          :src="require(`@icons/luggage.svg`)"
                        />
                        <b-img
                          v-else
                          style="height: 16px;"
                          :src="require(`@icons/no-luggage.svg`)"
                        />
                      </div>
                    </template>
                    <span
                      v-b-tooltip.hover.html.v-primary.top.window
                      :title="getMinFareOptions(item.fareOptions).groupClassName"
                    >
                      {{ getMinFareOptions(item.fareOptions).bookingClassAndSeatAvail }}
                    </span>
                  </div>

                  <!-- ANCHOR - DownIcon -->
                  <div class="fw-600 ml-50">
                    <feather-icon
                      v-if="!visible"
                      icon="ChevronDownIcon"
                      size="17"
                    />
                    <feather-icon
                      v-else
                      icon="ChevronUpIcon"
                      size="17"
                    />
                  </div>
                </div>
              </div>
            </b-col>

            <!-- ANCHOR - 8 Checkbox Chọn nhanh -->
            <b-col
              cols="1"
              :class="isMobileView ? 'text-center' : ''"
            >
              <template v-if="item.fareOptions.length">
                <feather-icon
                  v-if="selectedTrip && selectedTrip.id === item.id"
                  icon="CheckCircleIcon"
                  :size="['xs'].includes(appBreakPoint) ? '22' : '28'"
                  class="text-warning p-25"
                  @click.stop.prevent="handleQuickChoose(null, index, item.source)"
                />
                <feather-icon
                  v-else
                  icon="CircleIcon"
                  :size="['xs'].includes(appBreakPoint) ? '22' : '28'"
                  class="p-25 text-secondary"
                  @click.stop.prevent="handleQuickChoose(getMinFareOptions(item.fareOptions).id, index, item.source)"
                />
              </template>
            </b-col>
          </b-row>
          <!-- ANCHOR Chặng ẩn -->
          <div
            v-if="getFlightLegInfoList"
            class="d-flex-center text-danger font-italic font-small-5 font-weight-normal"
          >
            {{ $t('flight.flightHaveStop') }} {{ getFlightLegInfoList }}
          </div>
        </div>
      </slot>
    </b-card-header>

    <b-collapse
      :id="collapseItemID"
      v-model="visible"
      :accordion="accordion"
      role="tabpanel"
    >
      <slot />
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCollapse,
  BImg,
  BRow,
  BCol,
  VBTooltip,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'
import Ripple from 'vue-ripple-directive'
import cloneDeep from 'lodash/cloneDeep'
import { computed } from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'
import Vue from 'vue'
import isNil from 'lodash/isNil'

import store from '@/store'
import { BUSINESS_FILTER_REGEX, SKYBOSS_FILTER_REGEX, BUSINESS_SKYBOSS_FILTER_REGEX } from '@/constants/flight'

import {
  convertISODateTime, toHoursAndMinutes, formatCurrency, resolveAirlineFlightNumber,
} from '@core/utils/filter'

import useBookingHandle from '@flightv2/useBookingHandle'

const optionStop = [
  { text: 'direct', value: 0 },
  { text: '1_transits', value: 1 },
  { text: '2_transits', value: 2 },
]
export default {
  components: {
    BCard,
    BCardHeader,
    BCollapse,
    BImg,
    BRow,
    BCol,
    IAmLogoAirline: () => import('@/components/IAmLogoAirline.vue'),
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    minPrice: {
      type: Number,
      default: 0,
    },
    selectedTrip: {
      type: Object,
      default: () => {},
    },
    indexItinerary: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      visible: false,
      collapseItemID: '',
      openOnHover: this.$parent.hover,
      delay: { show: 500, hide: 500 },
      convertISODateTime,
      toHoursAndMinutes,
      formatCurrency,
      optionStop,
      countTickets: 0,
      classBooking: '',
      fareType: '',
    }
  },
  computed: {
    accordion() {
      return this.$parent.accordion
        ? `accordion-${this.$parent.collapseID}`
        : null
    },
    getShowPriceAfterDiscount() {
      return this.$store.getters['app-flight-v2/getShowPriceAfterDiscount']
    },
    getShowPrice() {
      return this.$store.getters['app-flight-v2/getShowPrice']
    },
    getShowBookingClass() {
      return this.$store.getters['app-flight-v2/getShowBookingClass']
    },
    getShowAircraft() {
      return this.$store.getters['app-flight-v2/getShowAircraft']
    },
    getCustomFeeServiceDataToModify() {
      return this.$store.getters['app-flight-v2/getCustomFeeServiceDataToModify']
    },
    getFeeServiceInSelectedProfile() {
      return this.$store.getters['app-flight-v2/getFeeServiceInSelectedProfile'](this.item?.domestic, this.item?.source)
    },
    fareTypeBusinessFilter() {
      return this.$store.getters['app-flight-v2/getFilterByFareTypeBusiness']
    },
    fareTypeSkybossFilter() {
      return this.$store.getters['app-flight-v2/getFilterByFareTypeSkyboss']
    },
  },

  created() {
    this.collapseItemID = uuidv4()
    if (this.item && this.item.fareOptions.length) {
      this.item.fareOptions.forEach(element => {
        // eslint-disable-next-line no-console, no-multi-assign
        this.countTickets = this.countTickets += element.seatAvailable
        this.classBooking = ` ${this.classBooking} ${element.bookingClass}`
        this.fareType = ` ${this.fareType === '' ? `${this.fareType}` : `${this.fareType},`} ${element.fareType}`
      })
    }
    this.visible = this.isVisible
  },

  methods: {
    updateVisible(val = true) {
      this.visible = val
      this.$emit('visible', val)
    },
    collapseOpen() {
      if (this.openOnHover) this.updateVisible(true)
    },
    collapseClose() {
      if (this.openOnHover) this.updateVisible(false)
    },
    getAirCraftByIata(code) {
      return this.$store.getters['globalConfig/getAirCraftByIata'](code)
    },
    getAirlineNameByCode(code) {
      return this.$store.getters['globalConfig/getAirlineNameByCode'](code)
    },
    resolveBorderColorByAirline(airline) {
      let textStyle = 'font-weight: 700;'
      if (['VJ', 'VZ'].includes(airline)) textStyle += 'border-left: 5px solid #EC2029 !important;'
      else if (airline === 'VU') textStyle += 'border-left: 5px solid #FFC80B !important;'
      else if (airline === 'VN') textStyle += 'border-left: 5px solid #166987 !important'
      else if (airline === 'QH') textStyle += 'border-left: 5px solid #64AC54 !important'
      else textStyle += 'border-left: 5px solid #000 !important'
      return textStyle
    },
    getMinFareOptions(fareOptions) {
      if (!fareOptions.length) {
        return {}
      }
      let fareOpt = fareOptions
      let skipSkyboss = false
      if (this.fareTypeBusinessFilter) {
        if (this.fareTypeSkybossFilter) {
          skipSkyboss = true
        }
        const filterRegex = this.fareTypeSkybossFilter ? BUSINESS_SKYBOSS_FILTER_REGEX : BUSINESS_FILTER_REGEX
        fareOpt = fareOptions.filter(fareItem => ((['1S', 'VN1A'].includes(this.item.source) && fareItem.groupClass.startsWith('B')) || (['QH', 'VJ', '1G'].includes(this.item.source) && filterRegex.test(fareItem.fareType))))
      }
      if (this.fareTypeSkybossFilter && !skipSkyboss) {
        fareOpt = fareOptions.filter(fareItem => ['VJ'].includes(this.item.source) && SKYBOSS_FILTER_REGEX.test(fareItem.fareType))
      }
      const minFare = fareOpt.find(item => {
        if (this.item?.id === this.selectedTrip?.id) {
          return item?.id === this.selectedTrip?.fareOptions?.id
        }
        return item.afterMultiple.totalAdultModified >= this.minPrice
      })
      const isSegmentFeeType = this.getFeeServiceInSelectedProfile?.feeType === 'FLIGHT_SEGMENT'
      const amountSegmentToMultipleFeeService = isSegmentFeeType ? this.item?.segments?.length : 1
      const customSfee = (
        !isNil(this.getCustomFeeServiceDataToModify?.adultAmount)
          ? this.getCustomFeeServiceDataToModify?.adultAmount
          : null
      )
      const selectedProfileSfee = (
        !isNil(this.getFeeServiceInSelectedProfile?.adultAmount)
          ? (this.getFeeServiceInSelectedProfile?.adultAmount * amountSegmentToMultipleFeeService)
          : null
      )
      const modifiedServiceFee = customSfee ?? selectedProfileSfee
      const serviceCustomFeeAdult = modifiedServiceFee !== null ? (modifiedServiceFee * minFare?.amountSegmentToMultipleFeeService - minFare?.serviceFeeAdult) : 0
      const discountTotal = this.getShowPriceAfterDiscount ? minFare?.discountAdult + minFare?.promotionAdult : 0
      const priceFareColAdult = minFare?.fareAdult - discountTotal + serviceCustomFeeAdult
      const priceFeeColAdult = minFare?.surchargeAdult + minFare?.taxAdult + (this.getShowPrice === 'NET_FARE' ? 0 : minFare?.serviceFeeAdult) + (minFare?.hideFee ? minFare?.agencyFee : 0)

      // F1 booking class dài => hiển thị 2 ký tự đầu
      const spilitedBookingClass = minFare?.bookingClass ? minFare?.bookingClass.split(' | ')[0] : ''
      const bookingClassShow = minFare?.bookingClass ? (spilitedBookingClass.length > 2 ? spilitedBookingClass.substring(0, 2) : spilitedBookingClass) : ''
      const groupClass = minFare?.groupClass || ''

      const { checkinBag: luggage } = this.getBaggageInSearchBooking({ ...this.item, fareOptions: minFare ? [minFare] : [] })
      return {
        bookingClassAndSeatAvail: `${bookingClassShow}(${minFare?.seatAvailable})`,
        groupClassName: groupClass,
        price: this.getShowPrice === 'TICKET_FARE' ? minFare?.fareAdult : priceFareColAdult + priceFeeColAdult,
        discount: discountTotal,
        id: minFare?.id,
        luggage: luggage || luggage === '' ? (luggage !== 'Không bao gồm' && luggage !== '') : null,
      }
    },
    updateSelected(fareId, indexTrip) {
      this.$bus.$emit('update-selected', { fareId, indexTrip })
    },
  },

  setup(props, { emit }) {
    const {
      airlineRoundtrip,
      convertShortenAircraft,
      getAirportByAirportCode,
      getSearchFlight,
      getBaggageInSearchBooking,
      checkDeniedRoundTripTAP,
    } = useBookingHandle()

    function handleQuickChoose(minFareId, indexTrip, source) {
      const draftSelectedTrip = store.getters['app-flight-v2/getDraftSelectedTrip']
      const isSearchRT = getSearchFlight.value?.type === 'RT'
      const isDomestic = props.item?.domestic

      const isDeniedRoundTripTAP = minFareId
        && isSearchRT
        && !isDomestic
        && !isEmpty(draftSelectedTrip.filter(Boolean))
        && checkDeniedRoundTripTAP(draftSelectedTrip, props.indexItinerary, props.item.clientId, source)

      if (isDeniedRoundTripTAP) {
        Vue.swal({
          title: this.$t('Notification'),
          text: this.$t('flight[\'For VNA round-trip tickets, you must choose a VNA departure and return trip!\']'),
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-warning',
          },
        })
      } else {
      // eslint-disable-next-line no-lonely-if
        if (!minFareId) {
          this.updateSelected(null, indexTrip)
          emit('update:selectedTrip', null)
          if ((airlineRoundtrip.value || source === '1G') && getSearchFlight.value?.type === 'RT') {
            store.dispatch('app-flight-v2/setAirlineRoundtrip', null)
            store.dispatch('app-flight-v2/setCombinationKeys', null)
            emit('clear') // clearSelectedTrip
          }
        } else {
          const fareOptions = props.item.fareOptions.find(fareItem => fareItem.id === minFareId)
          const selectedItem = {
            ...props.item,
            fareOptions,
          }

          this.updateSelected(selectedItem.fareOptions.id, indexTrip)
          emit('update:selectedTrip', cloneDeep(selectedItem))
          if (!selectedItem.domestic && getSearchFlight.value?.type === 'RT') {
            store.dispatch('app-flight-v2/setAirlineRoundtrip', selectedItem.source)
            store.dispatch('app-flight-v2/setCombinationKeys', selectedItem.fareOptions.combinationKeys)
          }
        }
      }
    }

    function getTripNameBySegments(item) {
      const departure = item.departure.IATACode
      const arrival = item.arrival.IATACode
      const stopPoints = item.stopPoint ? item.stopPoint.split(' | ') : []
      const segments = [departure, ...stopPoints, arrival]
      const tripArr = segments.map((pointItem, pointIndex) => {
        let changeAirport = ''
        if (pointIndex !== 0 && pointIndex !== (segments.length - 1)) {
          const index = item?.segments.findIndex(seg => seg?.arrival.IATACode === pointItem)
          const nextArrival = item?.segments[index + 1]?.departure.IATACode
          if (nextArrival !== pointItem) {
            changeAirport = nextArrival
          }
        }
        const res = {
          iataCode: `${pointItem}${changeAirport ? ` (${changeAirport})` : ''}`,
          name: getAirportByAirportCode(pointItem)?.name,
          transitTime: item?.segments.find(seg => seg?.arrival.IATACode === pointItem)?.transitTime,
        }
        return res
      })
      return tripArr
    }

    const resolveBadgeSource = (source, contentSource) => {
      if (['GDS', 'NDC'].includes(contentSource)) return `logo-${source} logo-${contentSource}`
      if (['F1', 'AK', 'TH', '1B'].includes(source)) return `logo-${source}`
      return ''
    }

    const getFlightLegInfoList = computed(() => {
      const text = []
      props.item.segments.forEach(segment => {
        if (!isEmpty(segment?.flightLegInfoList)) {
          // eslint-disable-next-line no-unused-expressions
          segment?.flightLegInfoList.forEach(flight => {
            // eslint-disable-next-line prefer-template
            text.push(flight.departure.IATACode + ' - ' + flight.arrival.IATACode)
          })
        }
      })
      return text.join(', ')
    })

    return {
      handleQuickChoose,
      getTripNameBySegments,
      convertShortenAircraft,
      getBaggageInSearchBooking,
      resolveBadgeSource,
      getFlightLegInfoList,
      resolveAirlineFlightNumber,
    }
  },
}
</script>

<style lang="scss" scoped>
.discount-badge::v-deep {
  position: absolute;
  top: -8px;
  padding: 0.2rem;
}
.discount-badge-del-price::v-deep {
  position: absolute;
  top: -4px;
  margin-left: 2px;
  padding: 0.33rem;
}

</style>
